import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ITheme, ThemeKey, getTheme, getThemeKey } from "../assets/css/variables";

import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';

import { useApi } from "../context/ApiProvider";
import { CartLookupRequest, CartWithItems, GetScriptsPartnersRequest, RedactedLocation } from "sparrowhub-client-axios";

import { ProgressStepKeys, setConfig, setScript } from '../store/scriptSlice'
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { Button, ButtonType } from "../components/Button";
import { InputField } from "../components/InputField";
import { SectionHeader } from "../components/SectionHeader";
import { Carousel } from "react-responsive-carousel";

const homepageHeaderSmiths = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-smiths.png`;
const homepageHeaderPriceline = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-priceline.png`;
const homepageHeaderChemistworks = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-chemistworks.png`;
const homepageHeaderCatAndClaudias = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-catandclaudias-new.png`;
const homepageBackground = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/homepage-bg.svg`;
const hotDocLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/hotdoc.png`;

const graphicTelehealth = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/graphic_telehealth.png`;

const escriptHelp = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/escript_help.png`;

const doordashLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-doordash.png`;
const auspostExpressLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-auspost-express.png`;
const auspostLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-auspost.png`;
const aramexLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-aramex.png`;

const arrowRight = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/arrow_right_fixed.svg`;

type HomePageProps = {
}

export const HomePage: FunctionComponent<HomePageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();

  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);

  const theme: ITheme = getTheme(config.theme_key);

  // state
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [showPage, setShowPage] = useState(false);

  // computed
  const hideBackground = (): void => {
    const elements = [
      document.body.getElementsByTagName('main')[0].parentElement,
      document.body.getElementsByTagName('footer')[0]
    ]
    elements.forEach((element: any) => {
      if (element) element.style.background = 'none';
    });
  }

  const carouselImages = (): Array<any> => {
    const partnerSlug = theme.images.customCarouselImages ? config.theme_key : 'generic';
    
    return [
      {
        src: `${process.env.REACT_APP_ASSET_BASE_PATH}/images/${partnerSlug}/scripts-carousel-1.png`,
        text: 'Enter the 18 digit token found on your e-script'
      },
      {
        src: `${process.env.REACT_APP_ASSET_BASE_PATH}/images/${partnerSlug}/scripts-carousel-2.png`,
        text: 'A pharmacist will prepare your medication'
      },
      {
        src: `${process.env.REACT_APP_ASSET_BASE_PATH}/images/${partnerSlug}/scripts-carousel-3.png`,
        text: 'Pick up your medication in-store or have it delivered to you'
      }
    ]
  }

  const videoOptions = (): any => {
    if (document.documentElement.clientWidth > 650) {
      return {
        width: '315',
        height: '177',
        playerVars: {
          autoplay: 1,
        }
      }
    } else {
      return {
        width: '275',
        height: '155',
        playerVars: {
          autoplay: 1,
        }
      }
    }
  }

  // methods
  const next = (formEvent: FormEvent): void => {
    formEvent.preventDefault();

    if (scripts[0].token !== '') {
      // manually submit GA event
      const formButtons = formEvent.currentTarget.getElementsByTagName('button');
      gtag('event', 'form_submit', {
        'form_id': formEvent.currentTarget.id,
        'form_name': (formEvent.currentTarget as any).name,
        'form_submit_text': formButtons[formButtons.length - 1].innerHTML
      });

      // handle next
      setShowPage(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setTimeout(() => {
        navigate('/patient');
      }, 300);
    }
  }

  const openExternal = (url: string): void => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(encodeURI(url), '_blank');
    }
  }

  const onPlayerReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const handleCartLookup = async (cartReference: string): Promise<void> => {
    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const recaptchaToken = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'cart_lookup'});

      const requestBody: CartLookupRequest = {
        security_token: recaptchaToken
      }
  
      api.cartLookup(cartReference, requestBody).then((response) => {
        const cart: CartWithItems = response.data.data;
        dispatch(setConfig({ cart_id: cart.id }));
      })
      .catch(error => {
        console.error(error);
        dispatch(setConfig({ cart_id: null }));
      })
    });
  }

  const renderCarouselIndicator = (
      clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
      isSelected: boolean,
      index: number,
      label: string
  ): React.ReactNode => {
    return (
      <div className={`customIndicator ${isSelected && 'selected'}`}></div>
    )
  }

  const handleStripeSetup = (): void => {
    if (config.payment_integration_key !== null) {
      loadStripe(config.payment_integration_key).then(result => {
        window.stripe = result;
      })
    } else {
      console.warn('No Stripe key found, unable to init Stripe.')
    }
  }

  // Retrieve the selected location if coming from the home page or Cart, or fallback to the default location for direct traffic
  const handleSetLocation = (partnerId: number | null, locations?: Array<RedactedLocation> | undefined): void => {
    if (partnerId !== null) {
      // Get the pre-selected location, if it exists
      let location: RedactedLocation | undefined = config.locations.find((location: RedactedLocation) => location.code === config.location_code);
      
      // If no pre-selected location, get the default location for that partner
      if (location === undefined && locations !== undefined && locations.length !== 0) {
        location = locations.find((location: RedactedLocation) => location.partner_id === partnerId && location.is_default);
      }

      // Store the selected location data
      if (location !== undefined && location !== null) {
        const address = location.address;
        const addressString = `${address.street ? JSON.parse(address.street).join(', ') : null}, ${address.city} ${address.region_code} ${address.postcode}`;
        dispatch(setConfig({
          location_code: location.code,
          location_name: address.name,
          location_address: addressString,
          location_phone: location.contact_phone,
          location_email: location.support_email
        }));
      } else {
        // handle Smith's Pharmacy case
        if (partnerId === 99) {
          dispatch(setConfig({
            location_code: 'cw-broadway', // set to CW Broadway to avoid error in demo
            location_name: 'Smith\'s Pharmacy Broadway',
            location_address: 'Shop G20A/1 Bay St, Ultimo NSW 2007',
            location_phone: '94123456',
            location_email: 'dev@rivalsoftware.com.au'
          }));
        } else {
          console.error('Error retrieving location data.')
          navigate('/partners');
        }
      }
    } else {
      console.error('Error retrieving a Partner ID.')
    }
  }

  const handleGetScriptsPartners = async (token: string, partnerId: number): Promise<void> => {
    const requestBody: GetScriptsPartnersRequest = {
      security_token: token
    }

    api.getScriptsPartners(requestBody)
      .then((response) => {
        if (response.data && response.data.status === 'success') {
          if (response.data.data.length === 0) {
            throw new Error('Request successful, 0 partners returned.');
          }

          const partners = response.data.data;
          let locations: Array<RedactedLocation> = [];
          partners.forEach(partner => {
            if (partner.locations !== undefined) {
              locations.push(...partner.locations);
            }
          });
          dispatch(setConfig({ locations }));
          handleSetLocation(partnerId, locations);
        } else {
          throw new Error('Unable to retrieve SparrowScripts partners.');
        }
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage('Unable to retrieve partner data. Please try again in a few minutes.')
      })
  }

  // initial page setup
  useEffect(() => {
    (async () => {
      // read cart param if not set already
      if (config.cart_id === null) {
        const cartParam = searchParams.get('cart');
        if (cartParam) {
          handleCartLookup(cartParam);
        }
      }
      

      // read partner param if not set already
      if (config.partner_id === null) {
        const partnerParam = searchParams.get('partner');
        const widgetPartner = (window as any).rival_scripts_partnerid;

        if (partnerParam === null && widgetPartner === undefined) {
          navigate('/partners');
        } else {
          let decodedPartnerId: number | null = null;
          try {
            decodedPartnerId = parseInt(atob(decodeURIComponent(partnerParam || widgetPartner || '')));
          } catch (error) {
            navigate('/partners');
          }

          const themeKey: ThemeKey = getThemeKey(decodedPartnerId!);

          // configure recaptcha key
          let recaptchaKey: string | undefined = undefined;
          if (process.env.REACT_APP_TARGET !== 'widget') {
            recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
          } else {
            if (window.rival_scripts_mode === 'live') {
              recaptchaKey = '6LeymTcpAAAAAGnDJ4l-pRZsI3Kiv8GB73WOmm0E';
            } else {
              recaptchaKey = '6LfvmjcpAAAAAN1fn5NdR1t2xpGM4bpZbDxjP09s';
            }
          }

          // set config in store
          dispatch(setConfig({
            partner_id: decodedPartnerId,
            theme_key: themeKey,
            has_landed: true,
            recaptcha_key: recaptchaKey,
            progress_step_key: ProgressStepKeys.Index
          }));

          setShowPage(true);

          // retrieve partner/location data if necessary
          if (config.locations.length === 0) {
            await grecaptcha.enterprise.ready(async () => {
              const token = await grecaptcha.enterprise.execute(recaptchaKey, { action: 'get_scripts_partners' });
              handleGetScriptsPartners(token, decodedPartnerId!);
            });
          } else {
            handleSetLocation(decodedPartnerId, config.locations);
          }

          if (process.env.REACT_APP_TARGET === 'widget' || themeKey === ThemeKey.Priceline) hideBackground();

          // dynamic document head settings
          const theme: ITheme = getTheme(themeKey);

          // font sizes
          const documentElement = document.documentElement;
          if (process.env.REACT_APP_TARGET !== 'app') {
            if (documentElement.clientWidth > 600) {
              documentElement.style.fontSize = theme.textSizes.desktop;
            } else {
              documentElement.style.fontSize = theme.textSizes.mobile;
            }
          } else {
            documentElement.style.fontSize = theme.textSizes[process.env.REACT_APP_TARGET! as keyof typeof theme.textSizes];
          }

          if (process.env.REACT_APP_TARGET !== 'widget') {
            // page title
            document.title = theme.data.name;
            // favicon
            let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = theme.images.favicon;
          } else {
            // 2024-05-31 Update: Requested that the widget visitors remain on the home page
            // skip to prescription page
            // navigate('/prescription');
          }
        }
      } else {
        setShowPage(true);
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    })();
  }, []);

  return (
    <StyledHomePage className={`pageTransition ${!showPage && 'hidden'}`}>
      <div className="HomePage_content">
        {/* CTA */}
        <div className="HomePage_cta fullWidth_parent">
          <div className="fullWidth_child">
          <SectionHeader line1="Prescription Medication" line2="delivered to your door" h1 />
            <p className="cta_text bold">Enter your e-script token</p>
            <form name="Enter your e-script token" id="form_home-page_escript-token" onSubmit={next}>
              <InputField type="text" value={scripts[0].token} onChange={(e: ChangeEvent) => dispatch(setScript({ index: 0, token: (e.target as HTMLInputElement).value }))} uppercase />
              <Button text="Find Prescription" type={ButtonType.Primary} />
            </form>
          </div>
        </div>

        {/* SWIPER */}
        <div className="HomePage_carousel fullWidth_parent">
          <div className="fullWidth_child">
            <Carousel
              // disable default UI
              showThumbs={false}
              showStatus={false}
              // inject custom UI
              renderIndicator={renderCarouselIndicator}
              // other props
              emulateTouch
              autoPlay
              interval={4000}
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeScrollTolerance={30}
            >
              {carouselImages().map((image: any, i: number) => {
                return (
                  <div key={`carousel-${i}`}>
                    <img src={image.src} />
                    <p className="legend">{image.text}</p>
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>

        {/* WHERE TO FIND TOKEN */}
        <div className="HomePage_help fullWidth_parent">
          <div className="fullWidth_child">
            <SectionHeader line1="Where can I find my" line2="e-script token?" />
            <img src={escriptHelp} alt="" />
            <p>Your e-script token is a unique 18 digit code that can be located below the QR code. </p>
          </div>
        </div>

        {/* TELEHEALTH */}
        <div className="HomePage_telehealth fullWidth_parent">
          <div className="fullWidth_child">
            <img className="graphic" src={graphicTelehealth} alt="" />
            <SectionHeader line1="Need a prescription" line2="from a doctor?" />
            <p>Book a Telehealth appointment after hours with our team at City After Hours Doctors. </p>
            <p>You will be connected to an Australian-registered doctor so you can receive high quality medical care from the comfort of your home.</p>
            <a href="https://www.hotdoc.com.au/medical-centres/glebe-NSW-2037/city-after-hours-doctors/doctors" target="_blank" rel="noreferrer">
              <Button text="Book a TeleHealth Appointment" type={ButtonType.Secondary} />
            </a>
            <img className="HomePage_hotDoc" src={hotDocLogo} alt="Delivered by: HotDoc" />
          </div>
        </div>
        
        {/* DELIVERY PARTNERS */}
        <div className="HomePage_couriers fullWidth_parent">
          <div className="fullWidth_child">
            <h2 className="semibold">We've partnered with trusted couriers for fast and reliable delivery</h2>
            <div className="logos">
              <img id="doordash" src={doordashLogo} alt="DoorDash" />
              <img id="auspost" src={auspostLogo} alt="Australia Post" />
              <img id="auspostExpress" src={auspostExpressLogo} alt="Australia Post Express" />
              {/* <img id="aramex" src={aramexLogo} alt="Aramex" /> */}
            </div>
          </div>
        </div>
        
        {/* FAQs */}
        <div className="HomePage_faq fullWidth_parent">
          <div className="fullWidth_child">
            <SectionHeader line1="Frequently Asked" line2="Questions" />
            <div className="question">
              <h3 className="bold">What is an e-script?</h3>
              <p>An e-script is a digital prescription sent to patients via SMS or email, containing a unique QR code or token that can be presented to a pharmacist to dispense medication. This system replaces the need for a physical paper prescription.</p>
            </div>
            <div className="question">
              <h3 className="bold">How do e-scripts work?</h3>
              <ol>
                <li><span className="bold">Issuance:</span> During a consultation, a doctor sends an eScript to the patient's mobile phone or email. The eScript includes a link to a QR code or token.</li>
                <li><span className="bold">Dispensing:</span> The patient presents the QR code or token to a pharmacist, who scans it to access the prescription details and dispense the medication.</li>
                <li><span className="bold">Repeats:</span> For repeat prescriptions, a new token is sent to the patient after each dispensing.</li>
              </ol>
            </div>
            <div className="question">
              <h3 className="bold">How safe and private are e-scripts?</h3>
              <p>E-scripts are encrypted and secure, ensuring that only authorised pharmacists can access the prescription details. This system helps maintain patient privacy and reduce the risk of fraudulent activities.</p>
            </div>
          </div>
        </div>
      </div>
    </StyledHomePage>
  );
}

const StyledHomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .HomePage_content {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    * {
      color: ${props => props.theme.colours.primaryDark};
    }

    .HomePage_cta {
      background: ${props => `linear-gradient(to bottom, ${props.theme.components.header.backgroundGradient})`};
      position: relative;
      z-index: 1;
      box-shadow: 0 -20px 0 10px ${props => props.theme.components.header.background};

      .cta_text {
        font-size: 1.125rem !important; // 16px
        font-weight: 400 !important;
        text-align: center;
        margin: 33px 0 5px 0;
      }
      
      .HomePage_header {
        margin: 70px 0 30px 0;
      }
      
      .HomePage_subheader {
        margin: -10px 0 50px 0;
        font-size: 1.25rem; // 20px
      }

      .InputField {
        margin: 0;
      }

      .Button_primary {
        margin-top: 15px;
        margin-bottom: 50px;
      }
    }

    .HomePage_carousel {
      padding-top: 55px;
      padding-bottom: 45px;

      .carousel {
        * {
          user-select: none;
        }

        img {
          width: 100%;
          height: 220px;
          object-fit: contain;
        }

        .legend {
          position: relative !important;
          bottom: unset;
          background: none;
          color: ${props => props.theme.colours.primaryDark};
          opacity: 1;
          font-size: 1rem; // 16px;
          font-weight: 500;
          margin-top: 29px;
          margin-bottom: 34px;
        }

        .control-arrow {
          background: none;
          opacity: 1;
          width: 50px;

          &:before {
            width: 11px;
            height: 22px;
            background: none;
            opacity: 1;
            border: none;
            padding: 5px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${arrowRight});
            transition: transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};
          }

          &.control-prev {
            transform: scaleX(-1);
          }

          @media screen and (max-width: 600px) {
            display: none;
            pointer-events: none;
          }
        }

        .control-dots {
          display: flex;
          margin: 0;
          width: 100%;
          align-items: center;
          justify-content: center;
          gap: 6px;

          .customIndicator {
            width: 10px;
            height: 10px;
            background: #E5E5E5;
            border-radius: 10px;

            transition:
              background-color ${props => `${props.theme.transitions.duration.default} ${props.theme.transitions.easing.inOutCubic}`},
              width ${props => `${props.theme.transitions.duration.default} ${props.theme.transitions.easing.inOutCubic}`};

            &.selected {
              background: ${props => props.theme.colours.input};
              width: 39px;
            }
          }
        }
      }
    }

    .HomePage_help {
      background: ${props => props.theme.colours.backgroundSecondary};

      .fullWidth_child {
        padding-top: 90px;
        padding-bottom: 90px;
        display: flex;
        flex-direction: column;

        img {
          width: 100%;
          max-width: 340px;
          height: auto;
          margin: -38px auto 0 auto;
        }
        
        p {
          font-size: 0.875; // 14px
          text-align: center;
        }
      }
    }

    .HomePage_telehealth {
      background: ${props => props.theme.colours.white};

      .fullWidth_child {
        padding: 70px 0;
        display: flex;
        flex-direction: column;

        .graphic {
          width: 100%;
          max-width: 340px;
          height: auto;
          margin: 0 auto;
        }

        p {
          font-size: 0.875rem; // 14px
          line-height: 165%;
          text-align: center;
          margin: 0 0 10px 0;
        }

        .Button {
          margin-top: 30px;
        }

        .HomePage_hotDoc {
          width: 105px;
          height: auto;
          margin: 0 auto;
        }
      }
    }

    .HomePage_couriers {
      background: ${props => props.theme.colours.backgroundSecondary};
      .fullWidth_child {
        padding: 30px 0;

        h2 {
          text-align: center;
          font-size: 1rem; // 16px
        }

        .logos {
          margin-top: 30px;
          margin-bottom: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 15px 25px;
          gap: 15px 25px;

          img {
            height: auto;

            &#doordash {
              width: 139px;
            }
            &#auspostExpress {
              width: 120px;
              grid-column: span 2;
              margin: 0 auto;
            }
            &#auspost {
              width: 123px;
            }
            &#aramex {
              width: 107px;
              margin-top: -11px;
            }

            &:nth-child(2n - 1) {
              margin-left: auto;
            }
          }
        }
      }
    }

    .HomePage_faq {
      background: ${props => props.theme.colours.backgroundDark};
      border-radius: 0 0 20px 20px;
      padding-bottom: 10px;
      margin-bottom: -40px;

      .question {
        background: ${props => props.theme.colours.white};
        border-radius: 20px;
        padding-bottom: 22px;
        margin-bottom: 15px;

        h3 {
          font-size: 0.875rem; // 14px
          background: ${props => props.theme.colours.backgroundSecondary};
          margin: 0;
          border-radius: 20px 20px 0 0;
          padding: 18px;
        }

        p {
          font-size: 0.875rem; // 14px
          padding: 0 18px;
          margin-bottom: 0;
        }

        ol {
          margin-top: 1rem !important;
          margin-bottom: 0;
          padding-right: 18px;
          
          li {
            font-size: 0.875rem; // 14px
            margin-bottom: 0.5rem; // 8px
  
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: ${props => props.theme.id === ThemeKey.Priceline && `url(${homepageBackground})`};
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .fullWidth_parent {
    /* width: 100vw !important;
    transform: translateX(calc((100vw - 640px) * -0.5)); */
    width: 10000px !important;
    transform: translateX(calc((10000px - 592px) * -0.5));

    display: flex;
    flex-direction: column;
    align-items: center;

    .fullWidth_child {
      width: 100%;
      max-width: 592px;
    }

    @media screen and (max-width: 650px) {
      width: calc(100% + 25px + 25px) !important;
      transform: translateX(-25px);
      padding: 0 24px;
    }
  }
`;
