import { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { useApi } from "../context/ApiProvider";
import { GetScriptsPartnersRequest, PolicyFeature, RedactedLocation } from "sparrowhub-client-axios";
import { ProgressStepKeys, setConfig } from '../store/scriptSlice'
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { SimpleSpinner } from "../components/SimpleSpinner";
import { Alert, AlertIcon, AlertType } from "../components/Alert";

const arrowIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/icon-arrow.svg`;

type PartnersPageProps = {
}

export const PartnersPage: FunctionComponent<PartnersPageProps> = () => {
  const dispatch = useAppDispatch();
  const api = useApi();

  // store
  const config = useAppSelector((state) => state.script.config);

  // state
  const [showPage, setShowPage] = useState(false);
  const [isLoading, setIsLoading] = useState(config.locations.length === 0);
  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const formatAddress = (address: any): string => {
    return `${JSON.parse(address.street).join(' ')}, ${address.city} ${address.region_code} ${address.postcode}`;
  }
  
  const getPartnerUrl = (location: RedactedLocation): string => {
    const param = encodeURIComponent(btoa(location.partner_id.toString()));
    return `/?partner=${param}`;
  }

  const trackSelectLocation = (location: RedactedLocation): void => {
    dispatch(setConfig({ location_code: location.code }));
    gtag('event', 'select_location', {
      'partner_id': location.partner_id,
      'location_code': location.code
    });
  }

  const handleGetScriptsPartners = async (token: string): Promise<void> => {
    const requestBody: GetScriptsPartnersRequest = {
      security_token: token
    }

    api.getScriptsPartners(requestBody)
      .then((response) => {
        if (response.data && response.data.status === 'success') {
          if (response.data.data.length === 0) {
            throw new Error('Request successful, 0 partners returned.');
          }

          const partners = response.data.data;
          let locations: Array<RedactedLocation> = [];
          partners.forEach(partner => {
            if (partner.locations !== undefined) {
              // Find locations assigned to the `scripts` feature
              const scriptsLocations: Array<RedactedLocation> = partner.locations.filter((location: RedactedLocation) =>
                location.policies.features.find((policy: PolicyFeature) => policy === PolicyFeature.Scripts));

              locations.push(...scriptsLocations);
            }
          });
          dispatch(setConfig({ locations }));
        } else {
          throw new Error('Unable to retrieve SparrowScripts partners.');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(`Error retrieving SparrowScripts partners: ${error}`);
        setErrorMessage('Unable to retrieve SparrowScripts partners. Please try again in a few minutes.')
        setIsLoading(false);
      })
  }

  useEffect(() => {
    (async () => {
      // reset partner selection
      dispatch(setConfig({
        location_code: null,
        location_name: null,
        location_address: null,
        location_phone: null,
        location_email: null,
        partner_id: null,
        theme_key: null,
      }));


      // configure recaptcha key
      let recaptchaKey: string | undefined = undefined;
      if (process.env.REACT_APP_TARGET !== 'widget') {
        recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
      } else {
        if (window.rival_scripts_mode === 'live') {
          recaptchaKey = '6LeymTcpAAAAAGnDJ4l-pRZsI3Kiv8GB73WOmm0E';
        } else {
          recaptchaKey = '6LfvmjcpAAAAAN1fn5NdR1t2xpGM4bpZbDxjP09s';
        }
      }
      
      // retrieve partner data
      await grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(recaptchaKey, { action: 'get_scripts_partners' });
        handleGetScriptsPartners(token);
      });

      document.title = 'SparrowScripts'
      dispatch(setConfig({ progress_step_key: ProgressStepKeys.Index }));
      setShowPage(true);
    })();
  }, []);

  return (
    <StyledPartnersPage className={`pageTransition ${!showPage && 'hidden'}`}>

      <h1>Fill your e-scripts online</h1>
      <h2>Get your medication from a pharmacy near you</h2>

      <SimpleSpinner show={isLoading} />
      {errorMessage &&
        <div style={{ marginTop: '20px' }}>
          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
            <p>{errorMessage}</p>
          </Alert>
        </div>
      }

      <div className={`Partners_grid ${config.locations.length === 0 && 'hidden'}`}>
        {config.locations.map((location: RedactedLocation, i: number) => {
          return (
            <NavLink to={getPartnerUrl(location)} key={i} onClick={() => trackSelectLocation(location)}>
              <div className="Partner_tile button_translate">
                <p className="Partner_select bold">
                  Select Pharmacy
                  <img src={arrowIcon} alt="" />
                </p>
                <p className="Partner_name bold">{location.name}</p>
                <p className="Partner_address">{formatAddress(location.address)}</p>
              </div>
            </NavLink>
          )
        })}
      </div>

    </StyledPartnersPage>
  );
}

const StyledPartnersPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 1.875rem; // 30px
    margin: 150px 0 0 0 ;
  }

  h2 {
    font-size: 1rem; // 16x
    margin: 0 0 40px 0;
  }

  .Partners_grid {
    display: grid;
    grid-template-rows: 177px;
    grid-gap: 20px;
    gap: 20px;
    
    /* Replacement while we're displaying 1 partner */
    // grid-template-columns: 1fr 1fr;
    grid-template-columns: 1fr;

    .Partner_tile {
      height: 100%;
      padding: 25px;
      border-radius: 6px;
      box-shadow: 0 0 5px rgba(180, 180, 180, 0.25);

      .Partner_select {
        font-size: 0.75rem; // 12px
        color: #BE1DF2;
        text-decoration: underline;
        text-align: right;
        margin-top: 0;

        img {
          margin-left: 9px;
        }
      }

      .Partner_name {
        font-size: 1rem; // 16px
        height: 55px;
        display: flex;
        align-items: center;
      }

      .Partner_address {
        font-size: 0.625rem; // 10px
      }
    }
  }

  @media screen and (max-width: 600px) {
    h1 {
      margin: 80px 0 0 0 ;
      width: 100%;
    }

    h2 {
      font-size: 0.875rem; // 14px
      margin: 10px 0 50px 0;
      width: 100%;
    }

    .Partners_grid {
      display: flex;
      flex-direction: column;
    }
  }
`;
