import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { store } from './store'
import { Provider } from 'react-redux'

import { Stripe, StripeElements } from '@stripe/stripe-js';

import App from './App';
import './assets/css/style.scss';

declare global {
  var grecaptcha: any;
  var gtag: any;
  interface Window {
    cordova: any;
    stripe: Stripe | null;
    stripeElements: StripeElements | null;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderReactDom = () => {
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
